import styled, { css } from "styled-components";
import CircularProgress from "@mui/material/CircularProgress";

const StyledButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  font-size: 14px;
  ${(props) => props.height && `height: ${props.height}`};
  ${(props) => props.weight && `font-weight: ${props.weight}`};
  ${(props) => (props.isPrimary ? primaryStyles : secondaryStyles)}
  & span {
    min-height: 20px;
    display: flex;
    align-items: center;
  }
  
  & .button-text {
    display: block;
    padding: 4px 20px;
  }
  &:disabled {
    color: white;
    background: #a8c8ff;
  }
  ${(props) => props.style && props.style}

  svg {
    width: 100% !important;
    height: 100% !important;
  }
`;

const StyledIcon = styled.img`
  width: 20px;
  height: 20px;
  margin-right: 10px;
`;

const primaryStyles = css`
  background-color: #0088ff;
  color: white;
  box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08),
    0px 4px 6px -2px rgba(16, 24, 40, 0.03);

  &:hover {
    background-color: #006db3;
  }

  &:active {
    background-color: #004c99;
  }
`;

const secondaryStyles = css`
  background-color: #fff;
  color: #1d2939;
  border: 1px solid #cdd5df;

  &:hover {
    background-color: #f2f2f2;
  }

  &:active {
    background-color: #e6e6e6;
  }

  &:disabled {
    background-color: #ccc;
    cursor: default;
  }
`;

const CircularProgressStyled = styled(CircularProgress)`
  max-width: 20px;
  max-height: 20px;
  color: white !important;
`;

const Button = (props) => {
  const {
    text,
    isLoading = false,
    isPrimary = true,
    style,
    iconSrc,
    iconStyle,
    disabled = false,
    height,
    className,
    ...buttonProps
  } = props;

  return (
    <StyledButton
      isPrimary={isPrimary}
      height={height}
      style={style}
      disabled={disabled || isLoading}
      className={className}
      {...buttonProps}
    >
      {iconSrc && <StyledIcon src={iconSrc} alt="button icon" style={iconStyle} />}
      {isLoading ? <CircularProgressStyled /> : <span>{text}</span>}
    </StyledButton>
  );
};
export default Button;
