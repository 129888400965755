import React from "react";
import { HashRouter, Route, Routes, Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import ForgotPassword from "/resources/JSX/networx/login/coLogin/ForgotPassword";
import CoLoginForm from "/resources/JSX/networx/login/coLogin/CoLoginForm";
import EmailSent from "/resources/JSX/networx/login/coLogin/EmailSent";
import { loginConstants } from "/resources/JS/constants/LoginConstants.js";

const CoLoginIndex = () => {
  const email = useSelector((state) => state.login.coEmail);
  const { EMAIL_SENT_HASH, FORGOT_PASSWORD_HASH, HOME } = loginConstants.routes;

  return (
    <React.Fragment>
      <div className="wrapper">
        <HashRouter hashType="slash">
          <Routes>
            <Route path={HOME} element={<CoLoginForm />} />
            <Route
              path={EMAIL_SENT_HASH}
              element={email?.length ? <EmailSent /> : <Navigate to={HOME} />}
            />
            <Route path={FORGOT_PASSWORD_HASH} element={<ForgotPassword />} />
          </Routes>
        </HashRouter>
      </div>
    </React.Fragment>
  );
};

export default CoLoginIndex;