import { useEffect, useRef } from "react";
import styled from "styled-components";

const ContainerWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: calc(var(--vh, 1vh) * 100);
  display: flex;
  justify-content: center;
  align-items: center;

  z-index: 999999999;
  ${(p) => !p.disableBackdrop && `background-color: rgba(0, 0, 0, 0.5);`}
`;

const updateVh = () => {
  // this is fixed for the bounce effect on iOS
  const vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty("--vh", `${vh}px`);
};


const Modal = ({ onClose = () => { }, children, allowBackdropClose, disableBackdrop, className }) => {
  const handleCloseModal = (e) => {
    if (e.target !== e.currentTarget) return;
    allowBackdropClose && onClose();
  };

  const modalRef = useRef(null);

  useEffect(() => {
    document.body.style.overflow = "hidden";
    document.body.style.touchAction = "none";
    updateVh();
    window.addEventListener("resize", updateVh);

    const modalRefCurrent = modalRef.current;
    modalRefCurrent.addEventListener("touchmove", (e) => e.stopPropagation());

    return () => {
      window.removeEventListener("resize", updateVh);
      modalRefCurrent.removeEventListener("touchmove", (e) => e.stopPropagation());
      const isModalsExist = document.querySelectorAll(".our-modal").length > 0;
      if (!isModalsExist) {
        document.body.style.overflow = "unset";
        document.body.style.touchAction = "unset";
      }
    };
  }, []);

  return (
    <div className={className}>
      <ContainerWrapper
        disableBackdrop={disableBackdrop}
        ref={modalRef}
        className="our-modal"
        onMouseDown={handleCloseModal}
      >
        {children}
      </ContainerWrapper>
    </div>
  );
};

export default Modal;

