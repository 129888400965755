import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { setIsSavingRequest } from '/resources/JS/modules/redux/actions/appStatusActions';
import { loginConstants } from '/resources/JS/constants/LoginConstants';
import LoginTemplate from '../LoginTemplate';
import Box from '@mui/material/Box';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { Link } from 'react-router-dom';
import { setCoEmail } from '/resources/JS/modules/redux/slices/loginSlice';
import { loginApi } from '/resources/JS/modules/restApi/LoginApi';
import { mixpanelService } from '/resources/JS/modules/tracking/MixpanelService';
import ModalForMobile from '../../../general/modal/ModalForMobile';
import { theme } from '../../../accounts/sharedComponents/theme';
import { redirectTo } from '../../../../JS/modules/general/utils';

const ModalForMobileStyled = styled(ModalForMobile)`
  width: 560px;

  ${theme.media.mobile} {
    width: 100%;
  }
`;
const Title = styled.h3`
  font-size: 26px;
  font-weight: bold;
  margin: 10px 0 20px;
  ${theme.media.mobile} {
    font-size: 23px;
    line-height: 1.2em;
  }
`;
const SubTitle = styled.h4`
  font-size: 18px;
  line-height: 1.5em;
  margin-bottom: 10px;
  font-weight: normal;
  ${theme.media.mobile} {
    font-size: 16px;
  }
`;

const CoLoginForm = () => {
  const dispatch = useDispatch();
  const [rememberMe, setRememberMe] = useState(true);
  const isSavingRequest = useSelector((state) => state.appStatus.isSavingRequest);
  const { HO_LOGIN, GENERATE_LEADS, FORGOT_PASSWORD_HASH } = loginConstants.routes;
  const component = 'CO Login form';
  const email = useSelector((state) => state.login.coEmail);
  const [formError, setFormError] = useState('');
  const urlParams = new URLSearchParams(window.location.search);
  const isInquirySubmitted = urlParams.get('inquiry_submitted');
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  useEffect(() => {
    if (isInquirySubmitted) {
      setTimeout(() => setIsPopupOpen(true), 500);
      setTimeout(() => setIsPopupOpen(false), 5000);
    }
    return () => dispatch(setIsSavingRequest(false));
  }, []);

  const onSubmit = async ({ email, password }) => {
    if (!isSavingRequest) {
      try {
        dispatch(setIsSavingRequest(true));
        setFormError('');
        dispatch(setCoEmail(email));
        const text = window.btoa(email + ':' + password);
        const res = await loginApi.loginAsCO(text, !!rememberMe ? 1 : 0);
        const redirect_url = res?.data?.redirect_url;
        redirectTo(redirect_url);
      } catch (error) {
        if (error?.response?.status !== loginConstants.general.REDIRECT_STATUS) {
          dispatch(setIsSavingRequest(false));
          setFormError(error?.response?.data?.response?.error_messages?.join(','));
        } else {
          const redirect_url = error.response?.data?.redirect_url;
          redirect_url && redirectTo(redirect_url);
        }
      }
    }
  };

  const trackLinkClick = (target, includeType = false) => {
    mixpanelService.trackClick(target, {
      component,
      ...(includeType && { type: 'HO' }),
    });
  };

  const handleCheck = (e) => {
    setRememberMe(!!e.target.checked);
  };

  const formContent = (
    <Box className="helper">
      <FormControlLabel
        control={
          <Checkbox
            checked={rememberMe}
            onChange={handleCheck}
          />
        }
        label="Remember me"
      />
      <Link
        to={FORGOT_PASSWORD_HASH}
        onClick={() => trackLinkClick('Forgot password')}
      >
        Forgot password
      </Link>
    </Box>
  );

  const actions = (
    <>
      <div>
        Don’t have an account?{' '}
        <a
          href={GENERATE_LEADS}
          onClick={() => trackLinkClick('Join as a pro')}
        >
          <b>Join as a Pro</b>
        </a>
      </div>
      <div>
        Are you a Homeowner?{' '}
        <a
          href={HO_LOGIN}
          onClick={() => trackLinkClick('Login', true)}
        >
          <b>Homeowner login</b>
        </a>
      </div>
    </>
  );

  return (
    <>
      {isInquirySubmitted && isPopupOpen && (
        <ModalForMobileStyled
          showCloseIcon
          buttons={null}
          setIsOpen={setIsPopupOpen}
        >
          <Title>It's good to see you again</Title>
          <SubTitle>
            We noticed that you already have an account. Just enter your details and we’ll get you
            on your way to getting leads (& booking jobs)!
          </SubTitle>
        </ModalForMobileStyled>
      )}
      <LoginTemplate
        title={'Welcome Back'}
        actions={actions}
        btnText={'Log in'}
        handleSubmit={onSubmit}
        includeEmail={true}
        includePassword={true}
        allowUserName={true}
        formContent={formContent}
        className="co-form"
        initValue={email}
        formError={formError}
        mixpanel={{
          page: 'TR_LOGIN',
          component,
          target: 'Log in',
        }}
      />
    </>
  );
};
export default CoLoginForm;
