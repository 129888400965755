import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import Button from '../../accounts/dashboard/shared/Button';

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  font-size: 16px;
  color: #1d2939;
  cursor: pointer;
  font-size: 16px;
  color: #1d2939;
  cursor: pointer;
  justify-content: center;
  gap: 12px;
  background-color: #fff;
  padding-top: 10px;

  button {
    width: calc(50% - 10px);
  }

  @media (max-width: 768px) {
    width: unset;
    ${({ fixedFooterMobile }) =>
      fixedFooterMobile &&
      css`
        position: fixed;
        left: 20px;
        right: 20px;
        bottom: 0;
        padding-bottom: 20px;
      `}
    border-top: 1px solid #e5e5e5;
  }
`;

const ButtonsPleaseholder = styled.div`
  display: none;

  @media (max-width: 768px) {
    display: block;
    height: 77px;
    width: 100%;
  }
`;

export const GenericStepButtons = ({
  first = {},
  second = {},
  className,
  fixedFooterMobile = true,
}) => {
  return (
    <div className={className}>
      {fixedFooterMobile && <ButtonsPleaseholder />}
      <ButtonsContainer fixedFooterMobile={fixedFooterMobile}>
        {first.text && (
          <Button
            text={first.text}
            isPrimary={false}
            onClick={first.onClick}
            data-testid={`modal_confirm_${first.text.toLowerCase()}`}
            className={`first-button modal_confirm_${first.text.toLowerCase()}`}
          />
        )}
        {second.text && (
          <Button
            text={second.text}
            onClick={second.onClick}
            isLoading={second.isLoading}
            disabled={second.disabled}
            data-testid={`modal_confirm_${second.text.toLowerCase()}`}
            className={`second-button modal_confirm_${second.text.toLowerCase()}`}
          />
        )}
      </ButtonsContainer>
    </div>
  );
};

export const useCurrentSlide = (initialStep, numSteps) => {
  const [currentStep, setCurrentStep] = useState(initialStep);

  const changeStep = (increamentBy) => {
    if (currentStep + increamentBy < 1 || currentStep + increamentBy > numSteps) {
      return;
    }

    setCurrentStep((prevSlide) => prevSlide + increamentBy);
  };

  return { currentStep, changeStep };
};

const GenericSteps = ({ steps: stepsProp }) => {
  const steps = stepsProp.filter(Boolean);
  const slide = useCurrentSlide(1, steps.length);
  const renderStep = steps[slide.currentStep - 1];

  return renderStep(slide);
};

export default GenericSteps;
