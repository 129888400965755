import React, { useState } from 'react';
import styled, { css } from 'styled-components';

import { replaceSpaceWithUnderscore } from '../../../JS/modules/general/utils';
import { GenericStepButtons } from '../steps/GenericSteps';

import Modal from './Modal';

const SelectContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  background-color: #fff;
  border-radius: 10px;
  padding: 24px;
  position: absolute;
  max-height: 95%;
  text-align: center;
  box-sizing: border-box;

  ${({ useProperLayout }) =>
    !useProperLayout &&
    css`
      overflow: auto;
    `}

  ::-webkit-scrollbar {
    width: 8px;
  }
  ::-webkit-scrollbar-track {
    background-color: #f1f1f1;
    border-radius: 5px;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #c1c1c1;
    border-radius: 5px;
  }

  @media (max-width: 768px) {
    width: 100%;
    background-color: #fff;
    position: absolute;
    min-width: unset;
    left: 0;
    right: 0;
    ${({ position }) =>
    position === "bottom" ? `
       bottom: 0;
       border-radius: 10px 10px 0 0;
    `: ``}
    align-items: stretch;
    padding: 20px;
    max-height: 100%;
    &.small-mobile-padding {
      padding: 14px;
    }
  }
  ${(p) => p.styles};
`;

const CloseIcon = styled.img`
  position: absolute;
  top: 16px;
  right: 16px;
  cursor: pointer;
`;

const SelectTitle = styled.div.attrs({ className: 'title' })`
  font-size: 16px;
  font-weight: 600;
  color: #1d2939;
  margin-bottom: 24px;
  position: relative;
`;

const ScrollableContent = styled.div`
  overflow-y: auto;
  grow: 1;
`;

export const useModalForMobile = () => {
  const [isOpen, setIsOpen] = useState(false);

  const openModal = () => {
    setIsOpen(true);
  };
  const closeModal = () => {
    setIsOpen(false);
  };

  return { openModal, closeModal, isOpen };
};

const ModalForMobile = ({
  id,
  title,
  setIsOpen,
  onClose,
  buttons = {
    cancel: { text: 'Cancel', onClick: () => { } },
    save: { text: 'Save', onClick: () => { }, isLoading: false },
  },
  isFullscreen = false,
  className,
  showCloseIcon = false,
  styles,
  allowBackdropClose = true,
  disableBackdrop,
  /**
   * @param {useProperLayout} This is used to make the modal not used the fixed footer
   * this will fixed scroll issues with the modal, pass `true` to enable it
   */
  useProperLayout = false,
  children,
  /**
   */
  position,
}) => {
  const modalSelector =
    typeof title === 'string' ? `modal_${replaceSpaceWithUnderscore(title).toLowerCase()}` : '';

  const handleClose = () => {
    setIsOpen && setIsOpen(false);
    onClose && onClose();
  };
  return (
    <Modal
      onClose={handleClose}
      disableBackdrop={disableBackdrop}
      allowBackdropClose={allowBackdropClose}
    >
      <SelectContainer
        id={id}
        className={className}
        data-testid={modalSelector}
        isFullscreen={isFullscreen}
        styles={styles}
        useProperLayout={useProperLayout}
        position={position ?? "bottom"}
      >
        {title && <SelectTitle>{title}</SelectTitle>}
        {showCloseIcon && (
          <CloseIcon
            src="/resources/images/accounts/leads/close.svg"
            alt="Close"
            onClick={handleClose}
          />
        )}
        {useProperLayout && (
          <ScrollableContent className="list-content">{children}</ScrollableContent>
        )}
        {!useProperLayout && <div className="list-content">{children}</div>}
        {buttons && (
          <GenericStepButtons
            first={buttons.cancel}
            second={buttons.save}
            fixedFooterMobile={false}
          />
        )}
      </SelectContainer>
    </Modal>
  );
};

export default ModalForMobile;
