import React from "react";
import ReactDOM from "react-dom/client";
import store from "/resources/JS/modules/redux/store";
import { Provider } from "react-redux";
import CoLoginIndex from "/resources/JSX/networx/login/coLogin/CoLoginIndex";
import ToastsTray from "../../../general/ToastSystem/ToastsTray";
import { initSentry } from "../../../utils/sentry";
initSentry();
const container = document.getElementById("contractor_login_root");
const root = ReactDOM.createRoot(container);
root.render(
  <Provider store={store}>
    <ToastsTray />
    <CoLoginIndex />
  </Provider>
);
