import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { loginApi } from "/resources/JS/modules/restApi/LoginApi";
import { loginConstants } from "../../../../JS/constants/LoginConstants";
import { setIsSavingRequest } from "../../../../JS/modules/redux/actions/appStatusActions";
import { useDispatch, useSelector } from "react-redux";
import LoginTemplate from "/resources/JSX/networx/login/LoginTemplate";
import { setCoEmail } from "/resources/JS/modules/redux/slices/loginSlice";

const ForgotPassword = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { EMAIL_SENT_HASH } = loginConstants.routes;
  const isSavingRequest = useSelector(
    (state) => state.appStatus.isSavingRequest
  );
  const component = "Forgot password form";
  const [formError, setFormError] = useState('');

  const onSubmit = async ({ email }) => {
    if (!isSavingRequest) {
      try {
        dispatch(setIsSavingRequest(true));
        dispatch(setCoEmail(email));
        const response = await loginApi.retrievePassword(email);
        const res = response?.data?.response;
        if (!!res?.is_success) {
          navigate(EMAIL_SENT_HASH);
        }
      } catch (error) {
          if (error.response.status !== loginConstants.general.REDIRECT_STATUS) {
            setFormError(error?.response?.data?.response?.error_messages?.join(','));
          }
        }finally {
        dispatch(setIsSavingRequest(false));
      }
    }
  };

  return (
    <LoginTemplate
      title="Forgot password?"
      className="forgot-pass"
      mainText="No problem, we’ll send you reset instructions."
      btnText="Send"
      handleSubmit={onSubmit}
      includeEmail={true}
      showBackLink={true}
      formError={formError}
      mixpanel={{
        page: "TR_FORGOT_PASSWORD",
        component,
        target: "Send link",
      }}
    />
  );
};
export default ForgotPassword;
