import React from "react";
import { loginApi } from "/resources/JS/modules/restApi/LoginApi";
import { useDispatch, useSelector } from "react-redux";
import LoginTemplate from "/resources/JSX/networx/login/LoginTemplate";
import { setIsSavingRequest } from "/resources/JS/modules/redux/actions/appStatusActions";

const EmailSent = () => {
  const email = useSelector((state) => state.login.coEmail);
  const dispatch = useDispatch();
  const isSavingRequest = useSelector(
    (state) => state.appStatus.isSavingRequest
  );
  const component = "CO Email sent form";
  const handleSubmit = async () => {
    if (!isSavingRequest) {
      try {
        dispatch(setIsSavingRequest(true));
        await loginApi.retrievePassword(email);
      } finally {
        dispatch(setIsSavingRequest(false));
      }
    }
  };

  return (
    <LoginTemplate
      title={"Check your email"}
      mainText={
        <span>
          We sent a password reset link to <b>{email}</b>.
        </span>
      }
      btnText={"Resend link"}
      handleSubmit={handleSubmit}
      showBackLink={true}
      initValue = {email}
      mixpanel={{
        page: "TR_CO_LOGIN_FORGOT_PASSWORD_EMAIL_SENT",
        component,
        target: "Resend link",
      }}
    />
  );
};
export default EmailSent;
